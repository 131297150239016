.placeholder-card {
    width: 80vw;
    max-width: 400px;
    height: 25vh;
    max-height: 200px;
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    background-color: #f3f3f3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;
}

.hamburger-menu-position {
    /* position: absolute; */
    top: 1rem;
    right: 2rem;
}

.main-heading {
    font-size: 5rem;
    font-weight: bold;
    margin-top: 3.5rem;
    margin-bottom: 2.5rem;
}

.share-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.share-preview-content {
    background: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    max-width: 600px;
    margin: auto;
}

.dark .share-preview-content {
    background: #191a1b;
}