@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  background: #fafafa;
  font-family: 'Oxygen', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Arial', sans-serif;
}


.event-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  /* Adds space between the image and the details */
}

.event-image-container {
  flex-basis: 400px;
  /* Adjust this value to fit the size of your image */
  /* max-width: 400px; */
  border-radius: 10px;
  /* Add border-radius */
  overflow: hidden;
  /* Ensures the border-radius applies to the image */
}

.event-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* This will ensure the image covers the area without stretching */
}

.event-details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-name {
  margin-bottom: 1rem;
  /* Adds space between the title and the graph card */
}


.event-bookings {
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 1;

  justify-content: center;
  align-items: center;
  padding: 1em;
}

.event-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.event-header {
  display: flex;
  flex-wrap: wrap;
  /* Allows the content to wrap on smaller screens */
}

.event-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.event-info div {
  margin-bottom: 1rem;
}

/* Links Section styles */
.link-preview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.participants-title {
  font-size: 24px;
  font-weight: bold;
}

.participants-description {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
}


/* Participant Search styles */
.participant-search {
  margin-bottom: 1rem;
}

/* Participant List styles */
.participant-list {
  overflow-y: auto;
  max-height: 100px;
  margin-bottom: 2rem;
}

.participant-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 5rem;
  width: 100px;
  height: 10px;
}

.qr-code {
  width: 10px;
  height: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  /* Adjust the gap as needed */
}

.button-wrapper {
  width: 100%;
  max-width: 80px;
  /* Adjust the max width as needed */
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  /* Aligns the button to the left */
  width: 100%;
  max-width: 80px;
  /* Adjust the max-width as needed */
}

/* Back Button styles */
.createform-button {
  /* z-index: 9999 !important; */
  /* padding: 10px 20px; */
  padding: 15px 25px;
  /* Increased padding */
  font-size: 18px;
  /* Increased font size */
  /* bottom: 20px; */
  margin-bottom: 2rem;
  left: 50%;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Makes the font bold */

}

.addBank-button{
  /* z-index: 9999 !important; */
  /* padding: 10px 20px; */
  padding: 15px 25px;
  /* Increased padding */
  font-size: 18px;
  /* Increased font size */
  bottom: 20px;
  /* margin-bottom: 2rem; */
  left: 50%;
  cursor: pointer;
  background-color: #000 !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

.connectStrip-button{
  z-index: 9999 !important;
  /* padding: 10px 20px; */
  padding: 15px 25px;
  /* Increased padding */
  font-size: 18px;
  /* Increased font size */
  bottom: 20px;
  margin-bottom: 2rem;
  left: 50%;
  cursor: pointer;
  background-color: #009ef7;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Makes the font bold */
}

.createform-button:hover {
  background-color: #555555;
}

/* Delete Button styles */
.deleteform-button {
  /* z-index: 9999 !important; */
  padding: 15px 25px;
  /* Consistent padding with the create button */
  font-size: 18px;
  /* Consistent font size with the create button */
  margin-bottom: 2rem;
  cursor: pointer;
  background-color: #ff0000;
  /* Red background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Bold font */
}

.cancelform-button{
  /* z-index: 9999 !important; */
  padding: 15px 25px;
  /* Consistent padding with the create button */
  font-size: 18px;
  /* Consistent font size with the create button */
  /* margin-bottom: 2rem; */
  cursor: pointer;
  background-color: #ff0000;
  /* Red background */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Bold font */
}

.deleteform-button:hover {
  background-color: #ff0000;
  /* Gray color on hover */
}

#full-page-scanner-overlay {
  pointer-events: none;
}

.back-button {
  /* padding: 10px 20px; */
  padding: 15px 25px;
  /* Increased padding */
  font-size: 18px;
  /* Increased font size */
  bottom: 20px;
  margin-bottom: 2rem;
  left: 50%;
  cursor: pointer;
  background-color: #000 !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Makes the font bold */
  transition: background-color 0.3s;
  /* smooth background color change on hover */
}

.back-button:hover {
  background-color: #555555;
}

/* Back Button styles */
.dashboard-button {
  /* padding: 10px 20px; */
  padding: 15px 25px;
  /* Increased padding */
  font-size: 18px;
  /* Increased font size */
  bottom: 20px;
  margin-bottom: 2rem;
  left: 50%;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  /* Makes the font bold */
  transition: background-color 0.3s;
  /* smooth background color change on hover */
}

.dashboard-button:hover {
  background-color: #555555;
}


.create-button {
  background-color: #000;
  /* black background */
  width: auto;
  /* Sets the width to be automatic, adjusting to content */
  color: #fff;
  /* white text */
  padding: 20px 30px;
  /* Increased padding */
  font-size: 20px;
  /* larger font size */
  border-radius: 6px;
  /* rounded corners */
  font-weight: bold;
  /* bold font */
  cursor: pointer;
  /* cursor changes on hover */
  transition: background-color 0.3s;
  /* smooth background color change on hover */
}

.create-button:hover {
  background-color: #555555;
}

/* Social Media Preview styles */
.social-media-links-container {
  display: grid;
  grid-template-columns: 1fr; /* Ensure a single column layout */
  gap: 1rem; /* Default gap for smaller screens */
  padding: 0rem;
}

.social-media-preview-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-top: 25%;
  width: 100%;
  height: 0%;
  border: none;
}

.social-media-preview-card iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 120%;
  height: 150%;
}

/* Additional styling for unsupported link types */
.unsupported-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
  color: #ada4a4;
  background: #f3f3f3;
  border-radius: 10px;
}


.scan-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.disable-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

/* Adjust the table cell padding for better spacing */
td {
  padding: 10px;
  text-align: center;
  /* or left, depending on your design preference */
}
.creator-qr-code{
  height: auto;
  max-width: 120px;
  width: 100%;
  margin-top: 20px;
}

.prev-next-button{
  border-radius: 5px;
  /* padding: 15px 15px !important; */
  font-size: 16px;
  font-weight: bold
}

.back-button-wrapper{
  margin-left: 13%;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .back-button-wrapper{
    margin-left: 3%;
  }
  .new-skip-btn{
    margin-right: 3%;
  }
  .addBank-button{
    /* margin-bottom: 2rem; */
    font-size: 16px;
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 600px) {
  .back-button {
    margin-bottom: 2rem;
    font-size: 16px;
    padding: 15px 15px;
  }

  .creator-qr-code{
    height: auto;
    max-width: 120px;
    width: 75%;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .social-media-links-container {
    gap: 1.5rem; /* Adjust gap for medium screens */
  }
}

@media only screen and (min-width: 768px) {
  .social-media-links-container {
    gap: 2rem; /* Adjust gap for larger screens */
  }
}

@media only screen and (min-width: 1024px) {
  .social-media-links-container {
    gap: 2.5rem; /* Adjust gap for extra-large screens */
  }
}

@media only screen and (min-width: 1280px) {
  .social-media-links-container {
    gap: 1rem; /* Adjust gap for the largest screens */
  }
}
