.button-group {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
    justify-content: center; /* Center buttons vertically */
    gap: 10px; /* Adjust the gap as needed */
    width: 100%; /* Ensures the button group does not exceed the card */
    padding: 0;
    margin: 0;
}

/* CSS for .description-container */
.description-container {
    height: 3em; /* Set a fixed height for three rows */
    overflow: hidden;
  }
  
  .event-description {
    /*overflow: scroll;  Enable scrolling if content exceeds three rows */
    white-space: pre-wrap; /* Preserve line breaks */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
    max-height: 3em; /* Limit the height to three rows */
  }
  
 
.button {
    background: transparent; /* No fill, just an outline */
    color: black; /* Text color */
    padding: 8px 16px;
    border: 2px solid black; /* Dark border */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    width: 100%; /* Full width of the button group */
    text-align: center;
    box-sizing: border-box; /* Includes padding in the width */
}

.dark .button {
  background: transparent; /* No fill, just an outline */
  color: white; /* Text color */
  padding: 8px 16px;
  border: 2px solid rgb(160, 149, 149); /* Dark border */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  width: 100%; /* Full width of the button group */
  text-align: center;
  box-sizing: border-box; /* Includes padding in the width */
}

.button:hover {
    /* background-color: #d3d3d3;s */
    background-color: #686D76;
    color: white; /* Text color on hover */
}
