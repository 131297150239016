/* Base styles */
.checkbox-wrapper * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  display: flex;
  align-self: center;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper label {
  --size: 50px;
  --shadow: calc(var(--size) * .07) calc(var(--size) * .1);
  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color, 0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.checkbox-wrapper label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * .7);
  height: calc(var(--size) * .7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper label:hover:before {
  width: calc(var(--size) * .55);
  height: calc(var(--size) * .55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}

.checkbox-wrapper label:active {
  transform: scale(0.9);
}

.checkbox-wrapper .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -.05);
  width: calc(var(--size) * .6);
  height: calc(var(--size) * .6);
  margin: 0 auto;
  margin-left: calc(var(--size) * .14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper .tick_mark:before,
.checkbox-wrapper .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * .1);
  height: calc(var(--size) * .3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -.68));
}

.checkbox-wrapper .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * .1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * .78));
}

.checkbox-wrapper input[type="checkbox"]:checked + label {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  box-shadow: 0 0 15px 6px rgba(255, 255, 255, 0.4);
}

.checkbox-wrapper input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

/* Sparkle Effects */
.sparkle {
  position: absolute;
  font-size: 20px;
  opacity: 0;
  pointer-events: none;
  animation: none;
  color: white;
}

/* Show sparkles on checkbox check */
.checkbox-wrapper input[type="checkbox"]:checked + label .sparkle {
  opacity: 1;
  animation: sparkle-burst 1.8s ease-out forwards;
}

/* 12 sparkles randomly placed */
.sparkle-1  { top: 10%; left: 10%;  animation-delay: 0s; }
.sparkle-2  { top: 20%; right: 15%; animation-delay: 0.05s; }
.sparkle-3  { bottom: 10%; left: 5%; animation-delay: 0.1s; }
.sparkle-4  { top: 30%; left: 30%; animation-delay: 0.15s; }
.sparkle-5  { top: 10%; right: 5%; animation-delay: 0.2s; }
.sparkle-6  { bottom: 15%; right: 15%; animation-delay: 0.25s; }
.sparkle-7  { top: 50%; left: 10%; animation-delay: 0.3s; }
.sparkle-8  { bottom: 5%; left: 25%; animation-delay: 0.35s; }
.sparkle-9  { top: 60%; right: 5%; animation-delay: 0.4s; }
.sparkle-10 { top: 40%; left: 50%; animation-delay: 0.45s; }
.sparkle-11 { bottom: 10%; right: 30%; animation-delay: 0.5s; }
.sparkle-12 { top: 25%; right: 40%; animation-delay: 0.55s; }

/* Sparkle Animation */
@keyframes sparkle-burst {
  0% {
    opacity: 0;
    transform: scale(0.4) translate(0, 0) rotate(0deg);
  }
  25% {
    opacity: 1;
    transform: scale(1.2) translate(-5px, -10px) rotate(45deg);
  }
  50% {
    transform: scale(1.6) translate(10px, -10px) rotate(90deg);
  }
  75% {
    transform: scale(1.8) translate(-15px, 10px) rotate(120deg);
  }
  100% {
    opacity: 0;
    transform: scale(2.3) translate(25px, -25px) rotate(180deg);
  }
}

/* The overlay that darkens the screen */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the popup appears above other content */
  padding: 20px; /* Padding to give space around the content */
}

/* The content area of the popup */
.popup-content {
  background-color: white; /* White background for the popup */
  padding: 20px;
  border-radius: 10px; /* Rounded corners for the popup */
  text-align: center;
  max-width: 400px; /* Limit the width of the popup */
  width: 100%; /* Ensure it doesn't overflow */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for the popup */
}

/* Style the paragraph */
.popup p {
  font-size: 16px;
  margin-bottom: 20px; /* Space between the paragraph and button */
  color: #333; /* Dark text color */
}

/* Style the button */
.popup button {
  background-color: #007bff; /* Blue background for the button */
  color: white; /* White text */
  padding: 10px 20px; /* Space inside the button */
  border: none;
  border-radius: 5px; /* Rounded corners for the button */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Button hover effect */
.popup button:hover {
  background-color: #0056b3; /* Darker blue when hovered */
}

.checkbox-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  transition: opacity 0.3s ease;
}

.checkbox-disabled input {
  cursor: not-allowed;
}




