.user-chat-experience-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #fff;
  }

  .dark .user-chat-experience-container {
    background: black;
  }
  .chat-history-container {
    width: 75%;
    margin: auto;
    position: relative; /* Establish a new stacking context */
    z-index: 1; /* Higher than the avatar's z-index */
    flex-grow: 1; /* Adjusted from 100 to 1 for better flexibility */
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px; /* Adjust bottom padding if necessary */
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; */
    margin-bottom: 90px; /* Add some space for the shadow of the input container */
  }
  
  .chat-history-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-history-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.0);
  }
  
  .chat-history-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 4px;
  }
  
  .chat-history-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.0);
  }
  
  .message {
    max-width: 70%; /* Adjust the max-width to match the input field width */
    margin-bottom: 10px;
    padding: 10px 15px;
  }
  
  .message.user-message {
    align-self: flex-end;
    background-color: rgba(0, 156, 208, .8);
    color: white;
    border-radius: 20px;
    margin-left: 400px;
    margin-right: 0px; /* Add margin on the right side for user messages */
    font-size: 20px; /* Increased text size */
    margin-bottom: 10px;
  }
  
  .message.bot-message {
    align-self: flex-start;
    background-color: rgb(188, 188, 188,.8);
    color: white;
    border-radius: 20px;
    margin-left: 0px;
    margin-right: 400px; /* Add margin on the right side for user messages */
    font-size: 20px; /* Increased text size */
  }

  .divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.divider:after {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    top: 14px;
    left: 0;
    border-top: 1px solid rgb(188, 188, 188,.8);
    width: 100%;
    height: 100%;
    z-index: -1;
}

.divider-text {
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  border-radius: 5px;
  line-height: 1.5;
  color: black;
  background: white;
  /* z-index: 777777; */
  display: inline-block;
  padding: 4px 8px;
  margin-bottom: 0;
}

  .chat-message.user-message {
    align-self: flex-end;
    background-color: rgba(0, 156, 208, .8);
    color: white;
    border-radius: 20px;
    margin-left: 90px;
    /* margin-top: 20px; */
    /* margin-right: 200px; */
    padding: 10px 10px;
    font-size: 20px; /* Increased text size */
  }
  
  .chat-message.bot-message {
    align-self: flex-start;
    background-color: rgb(188, 188, 188,.8);
    color: white;
    border-radius: 20px;
    padding: 10px 10px;
    /* margin-top: 20px; */
    /* margin-left: 200px; */
    margin-right: 90px;
    font-size: 20px; /* Increased text size */
  }
  
  .chat-input-container {
    position: relative; /* Establish a new stacking context */
    z-index: 1; /* Higher than the avatar's z-index */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); */
  }

  .avatar-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; /* Makes sure the avatar is behind other content */
  }

  .ticket-container{
    position: sticky;
    top: 0;
    width: 100px;
    transform: rotate(345deg);
    z-index: 10999;
    /* overflow: scroll */
  }

  .ticket-price{
    position: relative;
    top: -35px;
    left: 18px;
  }
  


  @media only screen and (min-width: 390px) and (max-width: 600px) {
    .chat-history-container {
      width: 100%;
    }
  
    .message {
      max-width: 100%;
      word-wrap: break-word;
    }
  
    .chat-message.bot-message {
      font-size: 16px;
      word-break: break-word;
    }
  
    .message.user-message {
      align-self: flex-end;
      background-color: rgba(0, 156, 208, 0.8);
      color: white;
      border-radius: 20px;
      margin-left: 0px;
      margin-right: 0px;
      font-size: 16px;
    }
  
    .message.bot-message {
      align-self: flex-start;
      background-color: rgba(188, 188, 188, 0.8);
      color: white;
      border-radius: 20px;
      margin-left: 0px;
      margin-right: 0px;
      font-size: 16px;
    }
  
    /* Make Spotify preview full width */
    .spotify-preview-container {
      width: 100%;
    }
  
    /* Ensure checkbox is positioned correctly */
    .checkbox-wrapper {
      white-space: nowrap;
      display: flex;
      align-items: start;
      justify-content: flex-start;
      padding-left: 10px; /* Adjust as needed */
    }
  
    /* Responsive container */
    .song-preview-wrapper {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 1093px) {
    .chat-history-container {
      width: fit-content;
    }
  
    .message {
      max-width: 100%; /* Allow messages to take 80% of the screen */
      word-wrap: break-word;
    }
  
    .message.user-message {
      align-self: flex-end;
      background-color: rgba(0, 156, 208, 0.8);
      color: white;
      border-radius: 20px;
      margin-left: 30%; /* Leaves 20% space on the left */
      margin-right: 0; /* Stays aligned to the right */
      font-size: 16px;
    }
  
    .message.bot-message {
      align-self: flex-start;
      background-color: rgba(188, 188, 188, 0.8);
      color: white;
      border-radius: 20px;
      margin-left: 0; /* Stays aligned to the left */
      margin-right: 20%; /* Leaves 20% space on the right */
      font-size: 16px;
    }
  
    /* Ensure Spotify preview takes 80% width within the message */
    .spotify-preview-container {
      max-width: 100%;
      width: auto; /* Prevent full-width stretching */
    }
  
    /* Ensure checkbox and preview are aligned without extra gaps */
    .checkbox-wrapper {
      white-space: nowrap;
      display: flex;
      align-items: center; /* Fixes gap issue */
      justify-content: flex-start;
      padding-left: 10px;
    }
  
    /* Responsive container */
    .song-preview-wrapper {
      display: flex;
      width: auto; /* Prevents extra spacing */
      flex-direction: row;
      align-items: center;
    }
  }
  
  
  
  

  
  