/* Support.css */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  gap: 50px; /* Ample space between components */
}

/* Support.css */
.video-container {
  position: relative;
  width: 100%; /* Full width */
  padding-top: 56.25%; /* Aspect ratio 16:9 */
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  transform: scale(1); /* Scale down the video player to 80% of its size */
  transform-origin: center; /* Ensure the scaling is centered */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}



.feedback-container {
  width: 100%;
  max-width: 100%; /* Set a max-width to prevent the form from stretching too wide */
  margin: auto; /* Center the feedback container */
}

/* Apply max-width to the form itself to control its width */
.feedback-container form {
  width: 100%;
 
  margin: 0 auto; /* Center the form in the feedback container */
}

/* Adjust the form elements to match the form width */
.feedback-container input,
.feedback-container textarea,
.feedback-container button {
  width: 100%; /* Make each form element take the full width of the form */
}

/* You can adjust the padding and margin of the form elements as needed */
.feedback-container input,
.feedback-container textarea {
  padding: 15px; /* Appropriate padding for the input and textarea */
  margin-bottom: 0px; /* Space between form elements */
}

.feedback-container button {
  padding: 15px 30px; /* Appropriate padding for the button */
  margin-top: 0px; /* Space between the textarea and button */
}
