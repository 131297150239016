@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  background: #fafafa;
  font-family: 'Oxygen', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Arial', sans-serif;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 16px;
  transition: box-shadow 0.3s ease-in-out;
}

.dark .card{
  background: black;
  color: white;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dark .card:hover{
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}

.card-title {
  font-size: 1.5em;
  margin-bottom: 16px;
}

.card-content {
  font-size: 1em;
  line-height: 1.6;
}

.flex-container {
  display: flex;
  gap: 20px;
  /* You can adjust the gap to your preference */
}

.flex-item {
  flex: 1;
}

.event-name {
  font-size: 2em;
  /* Adjust the font size as needed */
  font-weight: bold;
  /* This will make the text bold */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }
}

.event-description {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  font-style: bold;
}

.event-title-container {

  margin-top: 1rem;
  margin-bottom: 1rem;
}

.event-description-event {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.description-value {
  color: black;
  /* or any other light color */
  text-align: right;
  font-style: bold;
}

.description-name {
  color: black;
  text-align: left;
  font-style: bold;
}


.card-graph {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 16px;
  transition: box-shadow 0.3s ease-in-out;
  width: 50%; /* Half the width of the viewport */
  margin: 0 auto; /* Center the card */
}

.card-graph:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-graph-content {
  height: 400px; /* Fixed height for the content area */
}

/* Responsive adjustments for the card-graph */
@media (max-width: 768px) {
  .card-graph {
    width: 100%; /* Full width on smaller screens */
  }
}