@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  background: #fafafa;
  /* font-family: 'Oxygen', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Red Hat Display' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body{
  background-color: #191a1b;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.phone-input{
  border-radius: 0.75rem !important;
}

.dark .phone-input > input{
  background-color: black !important;
  border-color: #1e293b !important;
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.drag-drop{
  border: 1px dashed black
}

.dark .drag-drop{
  border: 1px dashed #747981
}

.dark .rdt_TableHead{
  background-color: black;
  color: white;
}

.dark .rdt_Pagination{
  background-color: black;
  color: white;
}

.dark #pagination-first-page, .dark #pagination-previous-page, .dark #pagination-next-page, .dark #pagination-last-page{
  background-color: white;
  margin-left: 5px;
}

.dark .rdt_Table > div{
  background-color: black;
  color: white;
}

.dark .rdt_TableHead > div{
  background-color: black;
  color: white;
}

.dark .rdt_TableRow{
  background-color: black;
  color: white;
}

.dark .rdt_TableRow:hover{
  outline-color: gray;
  border-color: gray;
}

.dark .rdt_TableRow > div{
  background-color: black;
  color: white;
}


@keyframes slow-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-slow-spin {
  animation: slow-spin 4s linear infinite;
}

::-webkit-scrollbar {
  width: 10px;
}

 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #686D76; 
  border-radius: 6px;
}

.dark ::-webkit-scrollbar-thumb {
  background: #686D76; 
  border-radius: 10px;
}


.features-section {
  /* Add styles for the features section container */
}

.feature-card {
  /* Add styles for the feature cards */
}

.feature-card:hover {
  /* Add hover styles for the feature cards */
}

/* Responsive grid styles for different breakpoints */
@media (min-width: 640px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  /* This will take the full height of the viewport */
}

.container {
  padding-left: 2rem;
  /* This is the default padding for mobile and smaller screens */
  padding-right: 2rem;
  /* Make this the same as padding-left for consistency on mobile */
  max-width: 100%;
  box-sizing: border-box;
  /* Ensures padding is included in the width */
}

/* Media query for non-mobile screens */
@media (min-width: 768px) {
  .container {
    padding-left: 5rem;
    /* Increased padding for larger screens */
    padding-right: 4rem;
    /* Increased padding for larger screens */
  }
}

/* Base layout for all screens, single column by default */
.upcoming-events-grid,
.past-events-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  /* Single column by default */
}

.dark-light-mode{
  top: 35px;
  right: 20px;
  z-index: 9999999
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #403c37;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
  transform: translateX(24px);
}

/* Animated Button Style Start */

.animated-btn{
  padding: 15px 35px;
  /* width: 100%; */
  background: transparent;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  text-transform: capitalize;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  border: 1px solid #000000;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.dark .animated-btn{
  border: 1px solid gray;
}

.animated-btn:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #4535C1;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

input[type='checkbox'] {
  accent-color: #4535C1;
}

.dark .country-name{
  color: black;
}

.animated-btn .text{
  position: relative;
  z-index: 4;
  font-size: 16px;
  /* font-weight: 600; */
}

.animated-btn:hover{
  color: white;
  width: 100%;
}

.animated-btn:hover:after{
  width: 100%;
}

.google-login-component > div{
  background-color: #4535C1 !important ;
}

.dark .css-166bipr-Input > input, .dark .css-1cfo1cf > input ,.dark .css-1dimb5e-singleValue{
  color: white !important;
}

/* Animated Button Style End */

/* Responsive columns for wider screens */
@media screen and (min-width: 768px) {
  .upcoming-events-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Columns expand as space allows */
  }

  .past-events-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Same as above for consistency */
  }
}



.social-media-previews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.social-media-preview-card {
  border: 1px solid #ccc;
  padding: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  /* Equal size for all preview cards */
  width: 150px;
  height: 150px;
}

.social-media-preview-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.event-date {
  height: 3rem;
  /* Adjust based on your font-size to fit two lines */
  overflow: hidden;
  /* text-overflow: wrap; */
  white-space: normal;
  /* Allows text to wrap */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  /* Remove default margins if necessary */
}

::-webkit-datetime-edit-year-field:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-hour-field:focus,
::-webkit-datetime-edit-minute-field:focus,
::-webkit-datetime-edit-ampm-field:focus
{
  background-color: black;
  color: white;
}

.dark ::-webkit-datetime-edit-year-field:focus,
.dark ::-webkit-datetime-edit-month-field:focus,
.dark ::-webkit-datetime-edit-day-field:focus,
.dark ::-webkit-datetime-edit-hour-field:focus,
.dark ::-webkit-datetime-edit-minute-field:focus,
.dark ::-webkit-datetime-edit-ampm-field:focus
{
  background-color: gray;
  /* color: white; */
}