.terms-of-service {
  font-family: 'Arial', sans-serif;
  padding: 2rem;
  max-width: 800px;
  margin: 40px auto;
  line-height: 1.6;
  color: #333; /* Or any color that suits your design */
}

.terms-of-service h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.terms-of-service h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.terms-of-service p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.privacy-policy {
  font-family: 'Arial', sans-serif;
  padding: 2rem;
  max-width: 800px;
  margin: 40px auto;
  line-height: 1.6;
  color: #333; /* Or any color that suits your design */
}

.privacy-policy h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.privacy-policy h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.privacy-policy p {
  font-size: 1rem;
  margin-bottom: 1rem;
}
