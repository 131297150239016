@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  background: #fafafa;
  font-family: 'Oxygen', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.basic-container {
    @apply container mx-auto md:p-20 p-5;
}

.full-screen {
    @apply w-full h-screen;
}

.custom-flex-container-1-3 {
    @apply w-full lg:w-1/3 bg-white flex justify-center items-center;
    flex-direction: column;

}



.custom-flex-container-2-3 {
    @apply w-full lg:w-2/3 bg-black flex justify-center items-center;
}

@font-face {
  font-family: 'Sequel100Black';
  src: url('../../public/fonts/sequel-100-black-85.ttf') format('truetype'); /* Update the path and format as needed */
  font-weight: normal;
  font-style: normal;
}